import { RouteConfig } from 'vue-router';

import { currentSite } from '@/helpers/currentSite';
import ClassicLayout from '@/layouts/ClassicLayout.vue';
import ModernLayout from '@/layouts/ModernLayout.vue';
import NavigationLayout from '@/layouts/NavigationLayout.vue';

const MainLayout = currentSite?.layout === 'modern' ? ModernLayout : ClassicLayout;

const Routes: RouteConfig[] = [
  {
    path: '/region',
    name: 'region',
    component: () => import('@/pages/RegionRedirectPage.vue'),
    props: true,
  },
  {
    path: '',
    name: 'layout',
    component: MainLayout,
    props: true,
    children: [
      {
        path: '/',
        name: 'redeem',
        props: true,
        component: () => import('@/pages/RedeemPage.vue'),
      },
      {
        path: '/shops',
        name: 'shops',
        component: () => import('@/pages/ShopListPage.vue'),
        props: true,
        children: [
          {
            path: ':id/:slug?',
            name: 'shopDetails',
            components: {
              shopDetails: () => import('@/components/redeem/shop-details/ShopDetails.vue'),
            },
            meta: {
              showShopModal: true,
              scrollToTop: false,
            },
            props: true,
          },
        ],
      },
      {
        path: '/payment/amazonpay',
        name: 'amazonPayReturn',
        component: () => import('@/components/redeem/payment/AmazonPayReturn.vue'),
      },
      {
        path: '/payment/unzer',
        name: 'unzerReturn',
        component: () => import('@/components/redeem/payment/UnzerReturn.vue'),
      },
      {
        path: '/thankyou',
        name: 'thankYou',
        component: () => import('@/components/redeem/ThankYou.vue'),
      },
      {
        path: '/merchantcode/:redeemLinkToken',
        name: 'merchantcode',
        component: () => import('@/pages/RedeemLinkPage.vue'),
        props: true,
      },
      {
        path: '/tpr/:ident',
        name: 'thirdParty',
        component: () => import('@/pages/ThirdPartyRedeemPage.vue'),
        props: true,
      },
      {
        path: '/activate/:orderSource/:orderReference/:orderHash',
        name: 'voucherActivation',
        component: () => import('@/pages/ActivationPage.vue'),
      },
      {
        path: '/reactivate',
        name: 'voucherReactivation',
        component: () => import('@/pages/ReactivationPage.vue'),
      },
      {
        path: '/redeem/resend-link',
        name: 'resendRedeemLink',
        component: () => import('@/pages/ResendRedeemLinkPage.vue'),
      },
      {
        path: '/prepaid',
        component: NavigationLayout,
        children: [
          {
            path: '',
            name: 'prepaidEnter',
            component: () => import('@/pages/PrepaidEnterPage.vue'),
          },
          {
            path: 'checkout',
            name: 'prepaidCheckout',
            component: () => import('@/pages/PrepaidCheckoutPage.vue'),
            props: true,
          },
          {
            path: 'finalized',
            name: 'prepaidFinalized',
            component: () => import('@/pages/PrepaidFinalizedPage.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/feedback/:ident/:rating',
        name: 'feedback',
        component: () => import('@/pages/FeedbackPage.vue'),
      },
      {
        path: '/voucher-pdf/:ident',
        name: 'notFound',
        component: () => import('@/pages/VoucherPdfPage.vue'),
        props: true,
      },
      {
        path: '/fraud-prevention/:ident',
        name: 'fraudPreventionForm',
        component: () => import('@/pages/FraudPreventionPage.vue'),
        props: true,
        meta: {
          showNavBar: false,
        },
      },
      {
        path: '',
        name: 'notFound',
        component: () => import('@/pages/NotFoundPage.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'findVariation',
    component: () => import('@/pages/FindVariationPage.vue'),
  },
];

if (currentSite.id === 'region') {
  Routes
    .find((e) => e.name === 'layout')
    ?.children
    ?.push({
      path: '/contact',
      name: 'contact',
      component: () => import('@/pages/RegionContactPage.vue'),
    });
}

export default Routes;
