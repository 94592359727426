import { AxiosError, AxiosResponse } from 'axios';
import _ from 'underscore';
import { Dispatch } from 'vuex';

import { useHighMaintenanceStore } from '@/store/modules/highMaintenance';
import api from '~api/api';
import i18n from '~i18n';
import {
  Locale,
  TranslationCache,
  Translations,
} from '~model';

interface State {
  loading: boolean;
  cache: TranslationCache;
}

declare global {
  interface Getters {
    getTranslationIsLoading: boolean;
    getLanguage: string;
    getLocale: Locale;
    generateAcceptLanguage: (locale: Locale) => string;
    getLanguageFromLocalStorage: string;
    getLanguageFromUrl: string;
    getLanguageFromBrowser: string;
    getPrioritizedLanguage: string;
    getCachedTranslationMessages: (locale: Locale) => Translations;
  }
}

/*
 * Parse the given parameter-content safely to a json-object.
 * Only if content is already a valid object, simply return it.
 * If its valid content / parsable as a json, convert and return it as a json object.
 * otherwise just return null.
 */
function parseJsonSafely(value: object | string | null) {
  try {
    return typeof value === 'object' ? value : JSON.parse(value);
  } catch (e) {
    return null;
  }
}

export default {
  state: <State> {
    loading: false,
    cache: {},
  },

  actions: {
    /*
     * Initialize the language of the user for vue-i18n.
     * but instead of the vue-i18n default locale, try to get the
     * "prioritized" language (first http-param, second session, third browser, etc.)
     */
    initLanguage(
      {
        dispatch,
        getters,
      }: {
        dispatch: Dispatch;
        getters: Getters;
      },
    ) {
      dispatch('setLocale', {
        locale: getters.getPrioritizedLanguage,
        store: false,
      });
      dispatch('changeLanguage', { store: false });
    },

    /*
     * Change the language of the page.
     * Set the axios header first (important for headers when fetching translations).
     * Try to get data from cache first by calling getCachedTranslationMessages.
     * If translations already cached, just call setTranslations.
     * Set locale after any of the two dispatch calls.
     */
    changeLanguage({
      dispatch,
      getters,
    }: {
      dispatch: Dispatch;
      getters: Getters;
    }, {
      locale = getters.getLocale,
      store = true,
      data = getters.getCachedTranslationMessages(locale),
    }) {
      api.defaults.headers.common['Accept-Language'] = getters?.generateAcceptLanguage(locale);

      dispatch(data ? 'setTranslations' : 'fetchTranslations', { locale, data })
        .then(() => {
          dispatch('setLocale', { locale, store });
        });
    },

    /*
     * Fetch the translations via api.
     * API call fetching translation JSON-Array (key-value pairs).
     * On success, set i18n locales.
     * On error, throw error message.
     */
    async fetchTranslations(
      {
        state,
        dispatch,
        getters,
      }: {
        state: State;
        dispatch: Dispatch;
        getters: Getters;
      },
      { locale = getters.getLocale } = {},
    ) {
      state.loading = true;
      i18n.silentTranslationWarn = true;

      return api
        .get('/v2/translation/dump/FrontendNew', {
          params: { placeholderWrap: '{|}' },
          headers: { 'Accept-Language': getters?.generateAcceptLanguage(locale) },
        })
        .then(async (response: AxiosResponse) => {
          const data = response?.data;
          const minSize = 2; // Minimum expected translation entries

          switch (true) {
            case _.size(parseJsonSafely(data)) > minSize: // Expect more than x entries on the response (safely parsed to JSON)
              dispatch('setTranslationsCache', { locale, data });
              dispatch('setTranslations', { locale, data });
              return true;

            default:
              await dispatch('loadLocalTranslations', locale);
              useHighMaintenanceStore().setHighMaintenance(data);
              return false;
          }
        })
        .catch(async (error: AxiosError) => {
          await dispatch('loadLocalTranslations', locale);
          useHighMaintenanceStore().setHighMaintenance(error);
        })
        .then(() => {
          state.loading = false;
          i18n.silentTranslationWarn = Boolean(parseInt(process?.env?.VUE_APP_I18N_SILENT_TRANSLATION_WARNING, 10));
        });
    },

    /*
     * Load the local translations. (Mostly just called if the api-request for translations fails.)
     */
    async loadLocalTranslations({
      dispatch,
    }: {
      dispatch: Dispatch;
    }, locale: Locale) {
      // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
      const messages = await require(`@/i18n/messages/${locale}.json`);
      dispatch('setTranslations', { locale, data: messages });
    },

    /*
     * Set the translation cache to state via mutation
     */
    setTranslationsCache({
      state,
    }: {
      state: State;
    }, {
      locale,
      data,
    }: {
      locale: Locale;
      data: AxiosResponse['data'];
    }) {
      state.cache[locale] = data;
    },

    /*
     * Set the i18n translation-messages from cache or response.
     */
    setTranslations({ getters }: { getters: Getters }, {
      locale = getters.getLocale,
      data = getters.getCachedTranslationMessages(locale),
    }) {
      i18n.setLocaleMessage(locale, data);
    },

    /*
     * Set the translation locale to i18n.
     * Also set documents HTML lang attribute.
     * The store param triggers if the language should be saved to the localStorage.
     * => Should be set when user triggers changeLanguage manually.
     * => Should not be set when the locale changes automatically.
     */
    setLocale({ getters }: { getters: Getters }, {
      locale = getters.getLocale,
      store = true,
    }) {
      i18n.locale = locale;
      document.querySelector('html')?.setAttribute('lang', getters.getLanguage);
      if (store) {
        localStorage.setItem('lang', getters.getLanguage);
      }
    },
  },

  getters: <GettersDefinition<State>> {
    /*
     * Get if the translation is loading
     */
    getTranslationIsLoading: (state: State) => state.loading,

    /*
     * Get the users language. Try to get it from the i18n locale and
     * use call get prioritized language as a alternative fallback
     */
    getLanguage: (
      _state: State,
      getters: Getters,
    ) => (i18n.locale ?? getters.getPrioritizedLanguage)?.substring(0, 2),

    /*
     * Get the users locale. combine language and country divided by -
     */
    getLocale: (
      _state: State,
      getters: Getters,
    ) =>
      `${getters.getLanguage}-${getters.getCountry}`,

    /*
     * Generate the axios headers accept language property.
     * take array of languages, remove nulls (compact), remove duplicates (unique) and
     * join it by , divider for a axios header type of header-option (string)
     */
    generateAcceptLanguage: (
      _state: State,
      getters: Getters,
    ) => (locale: Locale) =>
      _.chain([locale, getters.getLocale, getters.getLanguage])
        .compact()
        .uniq()
        .join(',')
        .value(),

    /*
     * Get the language from local storage
     */
    getLanguageFromLocalStorage: () => localStorage?.getItem('lang'),

    /*
     * Get the language from URL-Parameters
     * /?lang=de
     */
    getLanguageFromUrl: () => new URLSearchParams(window.location?.search).get('lang'),

    /*
     * Get the language from the browser (navigator)
     */
    getLanguageFromBrowser: () => navigator?.language,

    /*
     * Get the prioritized language for the build process
     */
    getPrioritizedLanguage: (
      _state: State,
      getters: Getters,
    ) => {
      const languages = getters.getConfigLanguages;
      const urlLanguage = getters.getLanguageFromUrl;
      const savedLanguage = getters.getLanguageFromLocalStorage;
      const browserLanguage = getters.getLanguageFromBrowser;

      switch (true) {
        case languages?.includes(urlLanguage):
          return urlLanguage;

        case languages?.includes(savedLanguage):
          return savedLanguage;

        case languages?.includes(browserLanguage):
          return browserLanguage;

        case languages?.length > 0:
          return languages?.[0];

        default:
          return i18n.locale;
      }
    },

    /*
     * Get the cached translation messages
     */
    getCachedTranslationMessages: (
      state: State,
      getters: Getters,
    ) => (locale = getters.getLocale) => state.cache[locale],
  },
};
