
import {
  computed,
  defineAsyncComponent,
  defineComponent,
} from 'vue';

import PageLoading from '@/components/PageLoading.vue';
import { useHighMaintenanceStore } from '@/store/modules/highMaintenance';
import store from '~store';
import Zendesk from '~widget/Zendesk.vue';

export default defineComponent({
  components: {
    PageLoading,
    HighMaintenance: defineAsyncComponent(() => import('~widget/HighMaintenance.vue')),
    Zendesk,
  },

  setup() {
    const { getters } = store;
    const appClass = computed(() => getters.getAppClass);
    const rootVariables = computed(() => getters.getRootVariables);
    const highMaintenanceActive = computed(() => useHighMaintenanceStore().getHighMaintenanceIsActive);
    const apiConfigFinished = computed(() => getters.getConfig.apiConfigFinished);

    return {
      appClass,
      rootVariables,
      highMaintenanceActive,
      apiConfigFinished,
    };
  },

  metaInfo() {
    const { getters } = store;
    const siteTitle = computed(() => getters.getMetaTitle);
    const faviconPath = computed(() => getters.getFaviconPath);

    return {
      title: siteTitle.value,
      link: [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `${faviconPath.value}/apple-touch-icon.png`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `${faviconPath.value}/favicon-32x32.png`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `${faviconPath.value}/favicon-16x16.png`,
        },
        {
          rel: 'icon',
          type: 'image/ico',
          href: `${faviconPath.value}/favicon-16x16.png`,
        },
        {
          rel: 'manifest',
          href: `${faviconPath.value}/site.webmanifest`,
        },
      ],
    };
  },
});
